// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

.site-title {
  @include relative-font-size(2);
  font-family: $title-font;
  font-weight: 400;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  @include media-query($on-palm) {
    padding-right: 45px;
  }

  &,
  &:visited {
    color: $brand-color-dark;
  }
}

.footer-heading {
  @include relative-font-size(1.5);
  font-family: $title-font;
  margin-bottom: $spacing-unit / 2;
}
